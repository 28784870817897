import styled from "styled-components";
import {
  breakpointSm
} from "@resultadosdigitais/tangram-design-tokens";

export const Container = styled.div`
    position: relative;

    &::before{
        content: "";
        display: block;
        padding-top: 100%;
    }

    &>div{
        position: absolute;
        top: 6px;
        left: 6px;
        right: 6px;
        bottom: 6px;
        border-radius: var(--showcase-border-radius);
        overflow: hidden;

        @media (max-width: ${breakpointSm}){
          top: ${(props) => props.showEmphasis ? 6 : 3}px;
          left: ${(props) => props.showEmphasis ? 6 : 3}px;
          right: ${(props) => props.showEmphasis ? 6 : 3}px;
          bottom: ${(props) => props.showEmphasis ? 6 : 3}px;
        }
    }
`;


export const Link = styled.a`
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
`;

export const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`

export const Subtitle = styled.div`
    position: absolute;
    left: 8px;
    right: 8px;
    bottom: 8px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    background-color: var(--color-white);
    border-radius: var(--showcase-border-radius);
    font-size: var(--text-size-xs);
    font-weight: var(--text-weight-bold);
    color: var(--color-gray-100);
`

export const MediaType = styled.div`
    position: absolute;
    top: 8px;
    left: 8px;
    width: 32px;
    height: 32px;
    filter: drop-shadow(2px 2px 2px #00000030);

    img{
        width: 100%;
        height: 100%;
    }

`