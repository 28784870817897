import React, { useMemo } from 'react'

import { Grid } from '@resultadosdigitais/tangram-components'

import { useMainContext } from '../../context/MainContext'

import Post from '../Post'
import CtaButton from '../CtaButton'

import { Container } from './ShowcaseStyled'

function ShowcaseGrid() {
  const { posts, showcase, buttons } = useMainContext()
  const { instagram_layout } = showcase

  const isShortGrid = useMemo(() => posts.length <= 4, [posts])

  const sizeColumnsGrid = {
    default: {
      largeDesktop: isShortGrid ? 6 : 4,
      desktop: isShortGrid ? 6 : 4,
      tablet: 6,
      mobile: 12,
      smallMobile: 12,
      rowJustify: 'center',
      containerClass: 'show-emphasis',
    },
    profile: {
      largeDesktop: 4,
      desktop: 4,
      tablet: 4,
      mobile: 4,
      smallMobile: 4,
      rowJustify: 'flex-start',
      containerClass: '',
    },
  }[instagram_layout]

  function renderButtons() {
    return (
      <Grid.Row fluid="true">
        {buttons &&
          buttons.map(button => (
            <Grid.Column key={button.id} withoutGutter={true} desktop={12}>
              <CtaButton button={button} />
            </Grid.Column>
          ))}
      </Grid.Row>
    )
  }

  function renderPosts() {
    return (
      <Grid.Row justify={sizeColumnsGrid['rowJustify']} fluid="true">
        {posts.map((post, index) => (
          <Grid.Column
            key={index}
            largeDesktop={sizeColumnsGrid['largeDesktop']}
            desktop={sizeColumnsGrid['desktop']}
            tablet={sizeColumnsGrid['tablet']}
            mobile={sizeColumnsGrid['mobile']}
            smallMobile={sizeColumnsGrid['smallMobile']}
            withoutGutter={true}
          >
            <Post key={post.instagram_media_id} post={post} />
          </Grid.Column>
        ))}
      </Grid.Row>
    )
  }

  return (
    <Container
      className={sizeColumnsGrid['containerClass']}
      isShortGrid={isShortGrid}
    >
      <Grid>
        {renderButtons()}
        {renderPosts()}
      </Grid>
    </Container>
  )
}

export default ShowcaseGrid
