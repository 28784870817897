import { IconButton, Link } from '@resultadosdigitais/tangram-components'
import styled from 'styled-components'

export const Container = styled.header`
  margin-top: var(--size-spacing-08);
  margin-bottom: var(--size-spacing-06);
`

export const InstagramUserWrapper = styled.section`
  text-align: center;
`

export const InstagramProfilePicture = styled.img`
  width: 77px;
  height: 77px;
  border-radius: 50%;
  margin-bottom: 8px;
`

export const InstagramProfileName = styled.p`
  font-size: 1.75rem;
  color: #212429;
  font-weight: 800;
  line-height: 130%;
`

export const InstagramUser = styled.a`
  color: var(--color-primary-90);
  text-decoration: none;
  color: #636e7c;
  font-size: 1.25rem;
  line-height: 150%;
  font-weight: 400;
`

export const StyledIconLink = styled(Link).attrs({
  rel: 'noreferrer',
  target: '_blank',
  kind: IconButton.kinds.neutral,
})`
  width: 32px;
  height: 32px;

  @media (min-width: 1024px) {
    width: 42px;
    height: 42px;
  }

  &:hover {
    color: transparent !important;
    background-color: transparent !important;
  }
  &:focus {
    outline: none;
    box-shadow: unset;
  }
  svg {
    width: 100%;
    height: 100%;
    fill: #97a1ac;
  }
`

export const ContainerIcons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: var(--size-spacing-05);
  column-gap: var(--size-spacing-04);
`
