import styled from 'styled-components'
import { breakpointSm } from '@resultadosdigitais/tangram-design-tokens'

const camelToSnakeCase = str =>
  str.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`)

function createStyle(obj) {
  const style = Object.entries(obj).reduce((old, style) => {
    return `${old} ${camelToSnakeCase(style[0])} : ${style[1]};`
  }, '')

  return style
}

export const Container = styled.div`
  position: relative;

  cursor: pointer;
  text-align: center;
  font-size: var(--text-size-sm);
  font-weight: var(--text-weight-bold);
  margin: 6px;
  background-color: var(--color-gray-100);
  color: var(--color-white);
  border: solid 1px transparent;
  border-radius: var(--showcase-border-radius);
  ${props => createStyle(props.styles)};

  @media (max-width: ${breakpointSm}) {
    margin: ${props => (props.showEmphasis ? 6 : 3)}px;
  }

  &:hover {
    ${props => createStyle(props.hover)}
  }
`

export const Link = styled.a`
  position: relative;
  display: block;
  padding: 13px 20px;
  color: inherit;
  text-decoration: none;
`
