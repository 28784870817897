import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { gaInstall } from '../../utils/Analytics'

import Header from '../Header'
import ShowcaseGrid from '../Showcase'

import LogoRDSM from '../../assets/LogoRDSM.svg'
import { Footer, FooterText } from './VitrineStyled'

import { useMainContext } from '../../context/MainContext'

const Showcase = () => {
  const { instagramUsername } = useParams()

  const { showcase } = useMainContext()
  const { ga4_measurement_id, ga4_id } = showcase

  useEffect(() => {
    document.title = instagramUsername ? `${instagramUsername}` : 'RD Station'
  }, [instagramUsername])

  useEffect(() => {
    if (ga4_measurement_id) {
      gaInstall(ga4_measurement_id, ga4_id)
    }
  }, [ga4_measurement_id, ga4_id])

  return (
    <>
      <Header instagramUsername={instagramUsername} />

      <section>
        <ShowcaseGrid />
      </section>

      <Footer>
        <FooterText>Empowered by</FooterText>
        <a
          href="https://www.rdstation.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={LogoRDSM} alt="RD Station Marketing" />
        </a>
      </Footer>
    </>
  )
}

export default Showcase
