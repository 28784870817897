import React from "react";
import Routes from "./routes";
import { Theme, Reset } from "@resultadosdigitais/tangram-components";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

	* {
		font-family: 'Nunito Sans', sans-serif;
	}

	#root{
		--showcase-border-radius: 8px;
	}

	body {
		background-color: #F8FAFF;
	}

	p {
		margin: 0;
	}
`;


const App = () => (
	<Theme value={Theme.kinds.marketing}>
		<Reset />
		<GlobalStyle />
		<Routes />
	</Theme>
);

export default App;
