import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { useParams } from 'react-router-dom'

import api from '../services/api'

import { SCREEN } from '../constants/SCREEN'

export const MainContextDefaultValues = {
  screen: SCREEN.LOADING,
  showcase: {},
  posts: [],
  buttons: [],
  profile: {},
}

export const MainContext = React.createContext(MainContextDefaultValues)

const compareByTimestamp = (a, b) => {
  if (a.timestamp < b.timestamp) return 1
  if (a.timestamp > b.timestamp) return -1
  return 0
}

const fetchData = async instagramUsername => {
  try {
    const showcase = await api.getShowcase(instagramUsername)
    const id = showcase.data.showcase?.id

    if (!id) return Promise.reject(null)

    const posts = await api.getShowcaseItems(id)

    posts.data.showcase_items.sort(compareByTimestamp)

    let buttons = []
    let profile = {}

    try {
      buttons = await api.getCtaButtons(id)
    } catch (_) {
      buttons = { data: { cta_buttons: [] } }
    }

    try {
      profile = await api.getProfile(id)
    } catch (_) {
      profile = { data: null }
    }

    return Promise.resolve({
      showcase: showcase.data.showcase,
      posts: posts.data.showcase_items,
      buttons: buttons.data.cta_buttons,
      profile: profile.data.profile,
    })
  } catch (_) {
    return Promise.reject(null)
  }
}

const MainContextProvider = ({ children, ...rest }) => {
  const [screen, setScreen] = useState(MainContextDefaultValues.screen)
  const [showcase, setShowcase] = useState(MainContextDefaultValues.showcase)
  const [posts, setPosts] = useState(MainContextDefaultValues.posts)
  const [buttons, setButtons] = useState(MainContextDefaultValues.buttons)
  const [profile, setProfile] = useState(MainContextDefaultValues.profile)
  const { instagramUsername } = useParams()

  useEffect(() => {
    fetchData(instagramUsername)
      .then(response => {
        setShowcase({
          ...response.showcase,
          instagram_layout: response.showcase.instagram_layout || 'default',
        })
        setPosts(response.posts)
        setScreen(SCREEN.HOME)
        setButtons(response.buttons)
        setProfile(response.profile || {})
      })
      .catch(_ => setScreen(SCREEN.NOT_FOUND))
  }, [instagramUsername])

  return (
    <MainContext.Provider
      value={{ screen, posts, showcase, buttons, profile, ...rest }}
    >
      {children}
    </MainContext.Provider>
  )
}

export const useMainContext = () => useContext(MainContext)

export default MainContextProvider

MainContextProvider.defaultProps = {
  children: null,
}

MainContextProvider.propTypes = {
  children: PropTypes.node,
}
