import React, { useMemo } from "react";

import {
  Facebook as FacebookTangram,
  Twitter as TwitterTangram,
  Linkedin as LinkedinTangram,
  Instagram as InstagramTangram,
  Whatsapp as WhatsappTangram,
  GlobeAmerica as GlobeAmericaTangram,
} from "@resultadosdigitais/tangram-react-icons";
import {
  Youtube as YoutubeExtended,
  Spotify as SpotifyExtended,
  Telegram as TelegramExtended,
  Tiktok as TiktokExtended,
} from "./ExtendedIcons";

export const DISABLED_FILL = "var(--color-gray-60)";

export const FacebookIcon = () => (
  <FacebookTangram fill={DISABLED_FILL} title={"Facebook"} />
);
export const TwitterIcon = () => (
  <TwitterTangram fill={DISABLED_FILL} title={"Twitter"} />
);
export const LinkedinIcon = () => (
  <LinkedinTangram fill={DISABLED_FILL} title={"Linkedin"} />
);
export const InstagramIcon = () => (
  <InstagramTangram fill={DISABLED_FILL} title={"Instagram"} />
);
export const WhatsappIcon = () => (
  <WhatsappTangram fill={DISABLED_FILL} title={"Whatsapp"} />
);
export const GlobeAmericaIcon = () => (
  <GlobeAmericaTangram fill={DISABLED_FILL} title={"GlobeAmerica"} />
);
export const TiktokIcon = () => (
  <TiktokExtended fill={DISABLED_FILL} title={"Tiktok"} />
);
export const YoutubeIcon = () => (
  <YoutubeExtended fill={DISABLED_FILL} title={"Youtube"} />
);
export const SpotifyIcon = () => (
  <SpotifyExtended fill={DISABLED_FILL} title={"Spotify"} />
);
export const TelegramIcon = () => (
  <TelegramExtended fill={DISABLED_FILL} title={"Telegram"} />
);

const icons = {
  facebook: <FacebookIcon />,
  twitter: <TwitterIcon />,
  linkedin: <LinkedinIcon />,
  instagram: <InstagramIcon />,
  tiktok: <TiktokIcon />,
  youtube: <YoutubeIcon />,
  spotify: <SpotifyIcon />,
  whatsapp: <WhatsappIcon />,
  telegram: <TelegramIcon />,
  site: <GlobeAmericaIcon />,
};

export const SocialIcon = ({ name }) => {
  if (!icons[name]) {
    throw new Error(
      `Name: ${name} not implemented in icons.. available icons ${JSON.stringify(
        Object.keys(icons)
      )}`
    );
  }

  return useMemo(() => icons[name], [name]);
};
