export const DEFAULT_SOCIAL_MEDIAS = [
  'instagram',
  'youtube',
  'tiktok',
  'spotify',
  'facebook',
  'twitter',
  'linkedin',
  'site',
  'telegram',
  'whatsapp',
]