import httpClient from './httpClient'

//showcase
const getShowcase = async instagramUsername => {
  return await httpClient.get(`/showcases/${instagramUsername}`)
}

const getShowcaseItems = async id => {
  return await httpClient.get(`/showcases/${id}/showcase_items`)
}

//cta_buttons
const getCtaButtons = async id => {
  return await httpClient.get(`/showcases/${id}/cta_buttons`)
}

//profile
const getProfile = async id => {
  return await httpClient.get(`/showcases/${id}/profile`)
}

export default {
  getShowcase,
  getShowcaseItems,
  getCtaButtons,
  getProfile,
}
