const g4_event_name = 'RD Link da Bio'

const gaInstall = (ga_id, showcase_identifier) => {
  installGA4(ga_id, showcase_identifier)
}

//GA4 Events
const gaPostClickEvent = (showcaseIdentifier, postId) => {
  gtag(`event`, g4_event_name, {
    rd_asset_id: showcaseIdentifier,
    rd_filter: `post:${postId}`,
    rd_action: 'clicked',
  })
}

const gaCtaButtonClickEvent = (showcaseIdentifier, ctaButtonId) => {
  gtag(`event`, g4_event_name, {
    rd_asset_id: showcaseIdentifier,
    rd_filter: `cta:${ctaButtonId}`,
    rd_action: 'clicked',
  })
}

const gaSocialClickEvent = (showcaseIdentifier, social) => {
  gtag(`event`, g4_event_name, {
    rd_asset_id: showcaseIdentifier,
    rd_filter: `social:${social}`,
    rd_action: 'clicked',
  })
}

const gtag = function () {
  // Can't use arrow func + destructuring as Google expects
  // arguments objects in dataLayer (not an array of arguments).
  window.dataLayer.push(arguments)
}

const installGA4 = (ga_id, showcase_identifier) => {
  const scriptId = `ga-gtag-${ga_id}`

  if (document.getElementById(scriptId)) return

  const { head } = document
  const script = document.createElement('script')

  script.id = scriptId
  script.type = 'text/javascript'
  script.async = true
  script.src = `https://www.googletagmanager.com/gtag/js?id=${ga_id}`

  head.insertBefore(script, head.firstChild)

  window.dataLayer = window.dataLayer || []
  gtag('js', new Date())
  gtag('config', ga_id)
  gtag('event', g4_event_name, {
    rd_asset_id: showcase_identifier,
    rd_filter: '',
    rd_action: 'viewed',
  })
}

export {
  gaInstall,
  gaPostClickEvent,
  gaCtaButtonClickEvent,
  gaSocialClickEvent,
}
