import styled from 'styled-components'

export const FooterText = styled.p`
  text-align: center;
  font-size: 11px;
  font-weight: 400;
  color: #74819f;
`

export const Footer = styled.footer`
  text-align: center;
  padding: 60px 0;
`
