import React from "react";
import { Spinner } from "@resultadosdigitais/tangram-components";
import styled from "styled-components";

const Root = styled.div`
  align-items: flex-end;
  display: flex;
  height: 50vh;
  justify-content: center;
`;

const Wrapper = styled.div`
  text-align: center;
`;

export const LoadingScreen = () => {
  return (
    <Root>
      <Wrapper>
        <Spinner size={Spinner.sizes.lg} />
      </Wrapper>
    </Root>
  );
};
