import React from "react";
import styled from "styled-components";

const mapSizes = { xs: 24, sm: 32, md: 40, lg: 48, xl: 64 };
export const sizes = { xs: "xs", sm: "sm", md: "md", lg: "lg", xl: "xl" };

const Svg = styled.svg`
  path:hover {
    fill: inherit;
  }
`;

export const Youtube = ({ title = "", fill = "none", size = sizes.xs }) => (
  <Svg
    width={mapSizes[size]}
    height={mapSizes[size]}
    viewBox={`-3 -6 ${mapSizes[size]} ${mapSizes[size]}`}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{title}</title>
    <path
      d="M17.6239 1.98027C17.4169 1.20079 16.8069 0.5869 16.0325 0.378566C14.6288 0 9 0 9 0C9 0 3.37127 0 1.96752 0.378566C1.19308 0.586933 0.583143 1.20079 0.376127 1.98027C0 3.39312 0 6.34091 0 6.34091C0 6.34091 0 9.28869 0.376127 10.7015C0.583143 11.481 1.19308 12.0693 1.96752 12.2777C3.37127 12.6562 9 12.6562 9 12.6562C9 12.6562 14.6287 12.6562 16.0325 12.2777C16.8069 12.0693 17.4169 11.481 17.6239 10.7015C18 9.28869 18 6.34091 18 6.34091C18 6.34091 18 3.39312 17.6239 1.98027ZM7.15908 9.01727V3.66454L11.8636 6.34097L7.15908 9.01727Z"
      fill={fill}
    />
  </Svg>
);

export const Spotify = ({ title = "", fill = "none", size = sizes.xs }) => (
  <Svg
    width={mapSizes[size]}
    height={mapSizes[size]}
    viewBox={`-3 -3 ${mapSizes[size]} ${mapSizes[size]}`}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{title}</title>
    <path
      d="M9 0C4.03186 0 0 4.03185 0 9C0 13.9681 4.03186 18 9 18C13.9681 18 18 13.9681 18 9C18 4.03185 13.9681 0 9 0ZM12.6544 13.2423C12.502 13.2423 12.4077 13.1952 12.2661 13.1117C10.0016 11.7472 7.36694 11.6891 4.76492 12.2226C4.62339 12.2589 4.43831 12.3169 4.33306 12.3169C3.98105 12.3169 3.75968 12.0375 3.75968 11.7435C3.75968 11.3698 3.98105 11.1919 4.25323 11.1339C7.2254 10.477 10.2629 10.5351 12.854 12.0847C13.0754 12.2262 13.206 12.3532 13.206 12.6835C13.206 13.0137 12.9484 13.2423 12.6544 13.2423ZM13.6306 10.8617C13.4419 10.8617 13.3149 10.7782 13.1843 10.7093C10.9161 9.36653 7.53387 8.82581 4.5254 9.64234C4.35121 9.68952 4.25686 9.73669 4.09355 9.73669C3.70524 9.73669 3.38952 9.42097 3.38952 9.03266C3.38952 8.64435 3.57823 8.38669 3.95202 8.28145C4.96089 7.99839 5.99153 7.7879 7.50121 7.7879C9.85645 7.7879 12.1319 8.37218 13.9246 9.43911C14.2185 9.61331 14.3347 9.8383 14.3347 10.154C14.331 10.546 14.0262 10.8617 13.6306 10.8617ZM14.7556 8.09637C14.5669 8.09637 14.4508 8.04919 14.2875 7.95484C11.7036 6.4125 7.08387 6.04234 4.09355 6.87702C3.9629 6.91331 3.7996 6.97137 3.6254 6.97137C3.14637 6.97137 2.77984 6.59758 2.77984 6.11492C2.77984 5.62137 3.08468 5.34194 3.41129 5.24758C4.68871 4.87379 6.11855 4.69597 7.6754 4.69597C10.3246 4.69597 13.1008 5.24758 15.1294 6.43064C15.4125 6.59395 15.5976 6.81895 15.5976 7.25081C15.5976 7.74435 15.1984 8.09637 14.7556 8.09637Z"
      fill={fill}
    />
  </Svg>
);

export const Telegram = ({ title = "", fill = "none", size = sizes.xs }) => (
  <Svg
    width={mapSizes[size]}
    height={mapSizes[size]}
    viewBox={`-2 -2 ${mapSizes[size]} ${mapSizes[size]}`}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{title}</title>
    <path
      opacity="0.8"
      d="M10 0C4.47714 0 0 4.47714 0 10C0 15.5229 4.47714 20 10 20C15.5229 20 20 15.5229 20 10C20 4.47714 15.5229 0 10 0ZM14.6352 6.80081C14.4847 8.38206 13.8335 12.2193 13.5021 13.9903C13.3619 14.7397 13.0859 14.991 12.8187 15.0155C12.2381 15.069 11.797 14.6318 11.2346 14.2631C10.3544 13.6861 9.85722 13.327 9.00286 12.764C8.01556 12.1134 8.6556 11.7559 9.21827 11.1712C9.36552 11.0183 11.9242 8.69101 11.9737 8.47988C11.9799 8.45347 11.9858 8.35488 11.9272 8.30311C11.8685 8.25133 11.7824 8.26887 11.7201 8.28294C11.6319 8.303 10.2258 9.23232 7.50206 11.0709C7.103 11.345 6.74152 11.4785 6.41762 11.4715C6.06056 11.4638 5.37375 11.2696 4.86315 11.1036C4.2369 10.9001 3.73915 10.7925 3.7825 10.4467C3.80508 10.2666 4.05306 10.0825 4.52645 9.89431C7.44167 8.62421 9.38559 7.78684 10.3582 7.38222C13.1353 6.2271 13.7124 6.02645 14.0885 6.01976C14.1712 6.01839 14.3562 6.03887 14.476 6.13609C14.5557 6.20534 14.6065 6.30198 14.6183 6.4069C14.6386 6.53716 14.6443 6.66929 14.6352 6.80081Z"
      fill={fill}
    />
  </Svg>
);

export const Tiktok = ({ title = "", fill = "none", size = sizes.xs }) => (
  <Svg
    width={mapSizes[size]}
    height={mapSizes[size]}
    viewBox={`-4 -3 ${mapSizes[size]} ${mapSizes[size]}`}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{title}</title>
    <path
      d="M15.7454 7.37965C14.1977 7.38335 12.6879 6.90066 11.4293 5.99977V12.2829C11.4289 13.4466 11.0732 14.5824 10.4098 15.5385C9.7464 16.4946 8.80693 17.2254 7.717 17.6332C6.62707 18.0409 5.43864 18.1062 4.3106 17.8203C3.18257 17.5344 2.16871 16.9109 1.40459 16.0332C0.640467 15.1555 0.162507 14.0655 0.0346191 12.9088C-0.0932691 11.7522 0.13501 10.584 0.688933 9.56062C1.24286 8.53721 2.09602 7.70729 3.13434 7.18185C4.17266 6.65641 5.34666 6.46048 6.49934 6.62027V9.78047C5.97187 9.61456 5.40546 9.61955 4.881 9.79475C4.35654 9.96994 3.90085 10.3064 3.57901 10.756C3.25716 11.2056 3.08562 11.7455 3.08888 12.2984C3.09213 12.8513 3.27002 13.3891 3.59714 13.8349C3.92426 14.2807 4.38388 14.6118 4.91037 14.7808C5.43686 14.9498 6.00328 14.9481 6.52876 14.776C7.05424 14.6039 7.51189 14.2701 7.83637 13.8224C8.16085 13.3747 8.33556 12.8358 8.33555 12.2829V0H11.4293C11.4271 0.26126 11.449 0.522164 11.4947 0.779414C11.6022 1.35368 11.8257 1.89999 12.1516 2.38491C12.4775 2.86983 12.8989 3.28317 13.39 3.59965C14.0886 4.06163 14.9078 4.30787 15.7454 4.3077V7.37965Z"
      fill={fill}
    />
  </Svg>
);
