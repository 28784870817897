import React from 'react'
import { gaSocialClickEvent } from '../../utils/Analytics'

import {
  Container,
  InstagramUserWrapper,
  InstagramProfilePicture,
  InstagramProfileName,
  InstagramUser,
  ContainerIcons,
  StyledIconLink,
} from './HeaderStyled'

import { SocialIcon } from '../Icons'

import { DEFAULT_SOCIAL_MEDIAS } from '../../constants/profileTypes'

import { useMainContext } from '../../context/MainContext'

const Header = ({ instagramUsername }) => {
  const INSTAGRAM_URL = `https://www.instagram.com/${instagramUsername}`
  const WHATSAPP_URL = 'https://api.whatsapp.com/send'

  const { showcase, profile } = useMainContext()
  const { ga4_id, name, profile_picture_url } = showcase

  const getWhatsappUrl = ({ number, message }) =>
    `${WHATSAPP_URL}?phone=${number}&text=${message}`

  const getSocialIconUrl = social => {
    if (social === 'whatsapp') return getWhatsappUrl(profile.whatsapp)

    return profile[social]
  }

  const renderSocialIcons = social => {
    if (!profile[social]) return null

    return (
      <StyledIconLink
        key={social}
        onClick={() => gaSocialClickEvent(ga4_id, social)}
        href={getSocialIconUrl(social)}
      >
        <SocialIcon key={social} name={social} />
      </StyledIconLink>
    )
  }

  return (
    <Container>
      <InstagramUserWrapper>
        <InstagramProfilePicture src={profile_picture_url} />
        <InstagramProfileName> {name} </InstagramProfileName>
        <InstagramUser href={INSTAGRAM_URL}>@{instagramUsername}</InstagramUser>

        <ContainerIcons>
          {DEFAULT_SOCIAL_MEDIAS.map(social => renderSocialIcons(social))}
        </ContainerIcons>
      </InstagramUserWrapper>
    </Container>
  )
}

export default Header
