import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { SCREEN } from './constants/SCREEN'
import MainContextProvider, { useMainContext } from './context/MainContext'
import { LoadingScreen } from './screens/Loading'
import NotFoundScreen from './screens/NotFoundScreen'
import VitrineScreen from './screens/Vitrine'

const screens = {
  [SCREEN.LOADING]: <LoadingScreen />,
  [SCREEN.HOME]: <VitrineScreen />,
  [SCREEN.NOT_FOUND]: <NotFoundScreen />,
}
const SwitchScreen = () => {
  const { screen } = useMainContext()
  return screens[screen]
}

const Wrapper = () => (
  <MainContextProvider>
    <SwitchScreen />
  </MainContextProvider>
)

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/:instagramUsername" component={Wrapper} />
      <Route path="*" component={NotFoundScreen} />
    </Switch>
  </BrowserRouter>
)

export default Routes
