import React from 'react'
import {
  EmptyState as Empty,
  Text,
} from '@resultadosdigitais/tangram-components'
import styled from 'styled-components'
import emptyStateImage from './empty-state.svg'
import locales from './locales/pt-BR.json'
import LogoRDSM from '../../assets/LogoRDSM.svg'

const FooterText = styled(Text)`
  color: #74819f;
`

const Footer = styled.footer`
  text-align: center;
`

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const EmptyState = styled(Empty)`
  max-height: 538px;
`

const NotFoundScreen = () => {
  const { title, description } = locales.emptyExpiredToken

  return (
    <Container>
      <div style={{ height: 100 }}></div>
      <EmptyState
        title={title}
        description={description}
        renderIllustration={
          <img src={emptyStateImage} alt={'emptyStateImage'} />
        }
      />
      <Footer>
        <FooterText>Empowered by</FooterText>
        <a
          href="https://www.rdstation.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={LogoRDSM} alt="RD Station Marketing" />
        </a>
      </Footer>
    </Container>
  )
}

export default NotFoundScreen
