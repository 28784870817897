import styled from "styled-components";
import {
  breakpointMax,
  breakpointXl,
  breakpointLg,
  breakpointMd,
  breakpointSm,
  breakpointXs
} from "@resultadosdigitais/tangram-design-tokens";

export const Container = styled.div`


  max-width: 1092px;
  margin: 0 auto;


  &.show-emphasis{
    max-width: 356px;
    margin: 0 auto;

    @media (min-width: ${breakpointXs}){
        max-width: 388px;
        margin: 0 auto;
    }

    @media (min-width: ${breakpointSm}){
        max-width: 752px;
        margin: 0 auto;
    }

    @media (min-width: ${breakpointMd}){
        max-width: ${({ isShortGrid }) => isShortGrid ? '752px' : '1092px'};
        margin: ${({ isShortGrid }) => isShortGrid ? '0 auto' : '0 6.6vw'};
    }

    @media (min-width: ${breakpointLg}){
        max-width: ${({ isShortGrid }) => isShortGrid ? '752px' : '1092px'};
        margin: 0 auto;
    }
    
    @media (min-width: ${breakpointXl}){
        max-width: ${({ isShortGrid }) => isShortGrid ? '752px' : '1092px'};
        margin: 0 auto;
    }

    @media (min-width: ${breakpointMax}){
        max-width: ${({ isShortGrid }) => isShortGrid ? '752px' : '1092px'};
        margin: 0 auto;
    }
  }






`